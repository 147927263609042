import { useQRScanner } from "@util/scanner";
import { useActions, useReduxState } from "re-reduced";
import React, { useEffect, useRef, useState } from "react";

import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { RequestStatus } from "@state/types";
import { Loading, QRCodeScanner } from "@sub";
import play from "@util/audio";
import { AESSecretKey } from "@util/constants";
import {
  truncateString,
  usePrevious
} from "@util/helper";
import {
  Container,
  P
} from "@util/standard";

const aes256 = require("aes256");
interface State {
  email: string;
  password: string;
  orgId: string;
}


const ScanToLogin = () => {
  if (typeof window == "undefined") {
    return null;
  }

  const initialState: State = {
    email: "",
    password: "",
    orgId: "",
  };

  const [state, setState] = useState<State>(initialState);
  const prevState = usePrevious(state);

  const videoRef = useRef<HTMLVideoElement>();
  const scanner = useQRScanner(videoRef.current);

  const { login, setErrorMessage } = useActions(actions);
  const { requestStatus } = useReduxState({
    requestStatus: selectors.getRequestStatus,
  });


  useEffect(() => {
    if (scanner.scanResult) {
      play.scanBarcode();

      const email = truncateString(scanner.scanResult, "TO:", ";");
      const encryptedPassword = truncateString(scanner.scanResult, "SUB:", ";");
      const orgId = truncateString(scanner.scanResult, "BODY:", ";");

      try {
        const password = aes256.decrypt(AESSecretKey, encryptedPassword);

        setState({ email, password, orgId });
      } catch (error) {
        setErrorMessage("error decrypting password");
      }
    }
  }, [scanner.scanResult]);

  // logging in with scanned data
  useEffect(() => {
    if (prevState !== state) {
      const { email, password, orgId } = state;
      const payload = { email, password, orgId };
      login(payload);
    }
  }, [state]);

  return (
    <Container flexDirection="column" alignItems="center" padding={0}>
      <P>LOGIN WITH QR CODE</P>

      <div style={{ width: "100%" }}>
        <QRCodeScanner videoRef={videoRef} ready={scanner.isReady} />
      </div>

      {requestStatus === RequestStatus.Pending && (
        <Container height="100%" justifyContent="center" alignItems="center">
          <Loading />
        </Container>
      )}
    </Container>
  );
};

export default ScanToLogin;
