import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useActions, useReduxState } from "re-reduced";

import * as selectors from "@state/selectors";
import actions from "@state/actions";
import Login from "@components/login";
import { pages } from "@util/constants";

const LoginPage = () => {
  const { loggedIn } = useReduxState({
    loggedIn: selectors.getLoggedIn,
  });
  const { updateRoute } = useActions(actions);

  useEffect(() => {
    if (loggedIn) {
      navigate(pages.home);
      return;
    }

    updateRoute("login");
  }, []);

  return <Login />;
};

export default LoginPage;
